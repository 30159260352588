<template>
    <div>
        <h3>Manage SEO </h3>
        <v-btn
                text
                :to="{ name: 'manage-websites', params: { domainname: siteUrl }}"
                outlined
                class="btn btn-lg btn-secondary-main"
                style="background:transparent;"
        >
            <i class="fas fa-arrow-left"></i> Back
        </v-btn>
        <span class="btn btn-label-primary btn-sm btn-bold kt-margin-r-10 pull-right">
      <i class="fa fa-globe"></i>
      {{siteUrl}}
    </span>
        <v-app>
            <div class="row mt-2">
                <div class="col-xl-12">

                            <v-row>
                                <div class="col-12">
                                    <KTPortlet v-bind:title="''">
                                        <template v-slot:body>
                                          <div class="row" v-if="loading">
                                            <div class="col-md-12">
                                              <v-skeleton-loader class="mx-auto" type="table"></v-skeleton-loader>
                                            </div>
                                          </div>
                                          <div class="row" v-if="!loading && pages && pages.length<1">
                                            <div class="col-md-12 text-center">
                                              <img src="@/assets/media/icons/no-data-found.png" class="no-data-img">
                                              <h5 class="mt-10">Looks like you have not added any data here. Trying adding some data.</h5>
                                            </div>
                                          </div>
                                          <div class="row"  v-if="!loading && pages && pages.length>1">
                                            <div class="col-md-12 text-center">
                                              <b-table
                                                  hover
                                                  responsive
                                                  :items="pages"
                                                  id="my-table"
                                                  :busy.sync="isBusy"
                                                  :fields="fields"
                                                  :current-page="currentPage"
                                                  :per-page="perPage"
                                              >
                                                <template v-slot:table-busy>
                                                  <div class="text-center text-info my-2">
                                                    <b-spinner class="align-middle"></b-spinner>
                                                    <strong>Loading Pages...</strong>
                                                  </div>
                                                </template>
                                                <template v-slot:cell(is_active)="data">
                                                  <i
                                                      v-if="data.item.is_active"
                                                      class="kt-nav__link-icon flaticon2-check-mark text-success"
                                                  ></i>

                                                  <i v-else class="kt-nav__link-icon flaticon2-cancel-music text-danger"></i>
                                                </template>
                                                <template v-slot:cell(seo_title)="data">
                                                      <span v-if="data.item.seo_title">
                                                          {{data.item.seo_title}}
                                                           <i
                                                               class="kt-nav__link-icon flaticon2-check-mark text-success"
                                                           ></i>
                                                      </span>
                                                  <span v-else >
                                          <i class="kt-nav__link-icon flaticon2-warning text-danger"></i>
                                    Fix SEO settings
                                    </span>

                                                </template>
                                                <template v-slot:cell(description)="data">
                                                  <!--                                    {{data.item.description}}-->
                                                  <span v-if="data.item.seo_description" class="elip">
                                        Fixed
                                         <i
                                             class="kt-nav__link-icon flaticon2-check-mark text-success"
                                         ></i>
                                    </span>
                                                  <span v-else >
                                          <i class="kt-nav__link-icon flaticon2-warning text-danger"></i>
                                    Fix SEO Description
                                    </span>

                                                </template>
                                                <!-- A virtual composite column -->
                                                <template v-slot:cell(action)="data">
                                                  <div class="kt-widget__toolbar" v-if="!data.item.deleted_at">
                                                    <a
                                                        href="#"
                                                        class="btn btn-clean btn-sm btn-icon btn-icon-md"
                                                        data-toggle="dropdown"
                                                    >
                                                      <i class="flaticon-more-1"></i>
                                                    </a>
                                                    <div class="dropdown-menu dropdown-menu-fit dropdown-menu-right">
                                                      <ul class="kt-nav">
                                                        <li class="kt-nav__item">
                                                          <a href="#" @click="changePage(data.item)" class="kt-nav__link">
                                                            <i class="kt-nav__link-icon flaticon-edit"></i>
                                                            <span class="kt-nav__link-text">Edit</span>
                                                          </a>
                                                        </li>
                                                      </ul>
                                                    </div>
                                                  </div>

                                                </template>
                                              </b-table>
                                            </div>
                                          </div>
                                        </template>
                                    </KTPortlet>
                                </div>
<!--                                <v-col v-if="pages.length<25" cols="12" md="9" lg="9" sm="12">-->
<!--                                <v-tabs vertical class="theme_settings " v-model="pageIndex" >-->
<!--                                    <v-tab v-for="(layout,index) in pages " :key="index">-->

<!--                                        {{layout.title}}-->
<!--                                    </v-tab>-->
<!--                                    <v-tab-item v-for="(layout,index) in pages " :key="index">-->

<!--                                                <v-row>-->
<!--                                                    <v-col cols="12">-->
<!--                                                        <v-card-title>SEO</v-card-title>-->
<!--                                                        <v-card-text>-->
<!--                                                            <v-text-field-->
<!--                                                                    label="Set a friendly URL"-->
<!--                                                                    v-model="page.custom_slug"-->
<!--                                                                    required-->
<!--                                                                    outlined-->
<!--                                                                    densed-->
<!--                                                            ></v-text-field>-->
<!--                                                            <v-text-field-->
<!--                                                                    label="Enter SEO Friendly Title"-->
<!--                                                                    v-model="page.seo_title"-->
<!--                                                                    required-->
<!--                                                                    outlined-->
<!--                                                                    densed-->
<!--                                                            ></v-text-field>-->
<!--                                                            <v-textarea-->
<!--                                                                    v-model="page.seo_description"-->
<!--                                                                    label="Enter SEO description to match page content"-->
<!--                                                                    outlined-->
<!--                                                                    densed-->
<!--                                                            ></v-textarea>-->
<!--                                                            <v-combobox-->
<!--                                                                    v-model="page.seo_keyword"-->
<!--                                                                    hint="Maximum of 5 keywords is recommended"-->
<!--                                                                    label="Add keywords"-->
<!--                                                                    multiple-->
<!--                                                                    persistent-hint-->
<!--                                                                    small-chips-->
<!--                                                                    outlined-->
<!--                                                                    densed-->
<!--                                                            >-->
<!--                                                                <template v-slot:no-data>-->
<!--                                                                    <v-list-item>-->
<!--                                                                        <v-list-item-content>-->
<!--                                                                            <v-list-item-title>-->
<!--                                                                                No results matching "-->
<!--                                                                                <strong>{{ search }}</strong>". Press-->
<!--                                                                                <kbd>enter</kbd> to create a new one-->
<!--                                                                            </v-list-item-title>-->
<!--                                                                        </v-list-item-content>-->
<!--                                                                    </v-list-item>-->
<!--                                                                </template>-->
<!--                                                            </v-combobox>-->
<!--                                                        </v-card-text>-->
<!--                                                    </v-col>-->

<!--                                                </v-row>-->

<!--                                    </v-tab-item>-->

<!--                                </v-tabs>-->
<!--                                </v-col>-->
<!--                                <v-col v-else cols="12" md="9" lg="9" sm="12">-->
<!--                                    <v-row>-->

<!--                                        <v-col cols="12">-->
<!--                                            <v-select-->
<!--                                                    :items="pages"-->
<!--                                                    v-model="page"-->
<!--                                                    @change=" changePage(`${page}`)"-->
<!--                                                    item-text="title"-->
<!--                                                    item-value="id"-->
<!--                                                    return-object-->
<!--                                                    filled-->
<!--                                                    outlined-->
<!--                                                    label="Select Page to continue"-->
<!--                                            ></v-select>-->
<!--                                        </v-col>-->
<!--                                        <v-col cols="12">-->
<!--                                            <v-card-title>SEO</v-card-title>-->
<!--                                            <v-card-text>-->
<!--                                                <v-text-field-->
<!--                                                        label="Set a friendly URL"-->
<!--                                                        v-model="page.custom_slug"-->
<!--                                                        required-->
<!--                                                        outlined-->
<!--                                                        densed-->
<!--                                                ></v-text-field>-->
<!--                                                <v-text-field-->
<!--                                                        label="Enter SEO Friendly Title"-->
<!--                                                        v-model="page.seo_title"-->
<!--                                                        required-->
<!--                                                        outlined-->
<!--                                                        densed-->
<!--                                                ></v-text-field>-->
<!--                                                <v-textarea-->
<!--                                                        v-model="page.seo_description"-->
<!--                                                        label="Enter SEO description to match page content"-->
<!--                                                        outlined-->
<!--                                                        densed-->
<!--                                                ></v-textarea>-->
<!--                                                <v-combobox-->
<!--                                                        v-model="page.seo_keyword"-->
<!--                                                        hint="Maximum of 5 keywords is recommended"-->
<!--                                                        label="Add keywords"-->
<!--                                                        multiple-->
<!--                                                        persistent-hint-->
<!--                                                        small-chips-->
<!--                                                        outlined-->
<!--                                                        densed-->
<!--                                                >-->
<!--                                                    <template v-slot:no-data>-->
<!--                                                        <v-list-item>-->
<!--                                                            <v-list-item-content>-->
<!--                                                                <v-list-item-title>-->
<!--                                                                    No results matching "-->
<!--                                                                    <strong>{{ search }}</strong>". Press-->
<!--                                                                    <kbd>enter</kbd> to create a new one-->
<!--                                                                </v-list-item-title>-->
<!--                                                            </v-list-item-content>-->
<!--                                                        </v-list-item>-->
<!--                                                    </template>-->
<!--                                                </v-combobox>-->
<!--                                            </v-card-text>-->
<!--                                        </v-col>-->
<!--                                    </v-row>-->
<!--                                </v-col>-->
<!--                                <v-col cols="12" md="3" lg="3" sm="12" class="mb-5">-->
<!--                                    <div class="">-->
<!--                                        Search-->
<!--                                        <strong>Preview</strong>-->
<!--                                    </div>-->
<!--                                    <v-card class="search-preview">-->
<!--                                        <v-card-text>-->
<!--                                            <p>{{siteUrl}}/{{page.custom_slug}}</p>-->
<!--                                            <h3>{{(page.seo_title)?page.seo_title:""}}</h3>-->
<!--                                            <p>{{(page.seo_description)?page.seo_description:''}}</p>-->
<!--                                        </v-card-text>-->
<!--                                    </v-card>-->
<!--                                    <div class=" mt-5" v-if="page.title">-->
<!--                                        Social Share-->
<!--                                        <strong>Image</strong>-->
<!--                                    </div>-->
<!--                                    <v-card class="search-preview" v-if="page.title">-->
<!--                                        <v-img-->
<!--                                                height="250"-->
<!--                                                width="100%"-->
<!--                                                v-bind:src="page.social_share_img ? page.social_share_img : '/assets/media/logos/icon-no-image.svg'"-->
<!--                                        >-->
<!--                                            <v-overlay :absolute="absolute">-->
<!--                                                <v-btn text @click="showDialog">Change Image</v-btn>-->
<!--                                            </v-overlay>-->
<!--                                        </v-img>-->

<!--                                        <p>{{siteUrl}}/{{page.custom_slug}}</p>-->
<!--                                        <h3>{{(page.seo_title)?page.seo_title:""}}</h3>-->
<!--                                        <p>{{(page.seo_description)?page.seo_description:''}}</p>-->
<!--                                    </v-card>-->
<!--                                </v-col>-->
                            </v-row>
                </div>
            </div>
            <v-dialog scrollable v-model="edit_seo" persistent max-width="1200">
                <v-card>
                    <v-card-title>SEO Settings</v-card-title>
                    <v-card-text>
                        <v-row>
                            <v-col cols="9">
                                <v-card-text>
                                    <v-text-field
                                            label="Set a friendly URL"
                                            v-model="page.custom_slug"
                                            required
                                            outlined
                                            densed
                                    ></v-text-field>
                                    <v-text-field
                                            label="Enter SEO Friendly Title"
                                            v-model="page.seo_title"
                                            required
                                            outlined
                                            densed
                                    ></v-text-field>
                                    <v-textarea
                                            v-model="page.seo_description"
                                            label="Enter SEO description to match page content"
                                            outlined
                                            densed
                                    ></v-textarea>
                                    <v-combobox
                                            v-model="page.seo_keyword"
                                            hint="Maximum of 5 keywords is recommended"
                                            label="Add keywords"
                                            multiple
                                            persistent-hint
                                            small-chips
                                            outlined
                                            densed
                                    >
                                        <template v-slot:no-data>
                                            <v-list-item>
                                                <v-list-item-content>
                                                    <v-list-item-title>
                                                        No results matching "
                                                        <strong>{{ search }}</strong>". Press
                                                        <kbd>enter</kbd> to create a new one
                                                    </v-list-item-title>
                                                </v-list-item-content>
                                            </v-list-item>
                                        </template>
                                    </v-combobox>
                                </v-card-text>
                                <v-card-actions>
                                    <v-row>
                                        <v-col class="pull-right text-right">
                                            <v-btn
                                                    color="btn btn-secondary text-gray"
                                                    class=""
                                                    depressed
                                                    @click="cancel()"
                                            >Cancel
                                            </v-btn>
                                            <v-btn
                                                    color="btn btn-primary"
                                                    class=" ml-2"
                                                    depressed
                                                    :loading="isBusy"
                                                    @click="saveSeoSetting"
                                            >Save
                                            </v-btn>
                                        </v-col>
                                    </v-row>

                                </v-card-actions>
                            </v-col>
                            <v-col cols="12" md="3" lg="3" sm="12" class="mb-5">
                                <div class="">
                                    Here is the Preview on
                                    <strong>Google's</strong> Search
                                </div>
                                <v-card class="search-preview">
                                    <v-card-text>
                                        <p>{{siteUrl}}/{{page.custom_slug}}</p>
                                        <h3>{{(page.seo_title)?page.seo_title:""}}</h3>
                                        <p>{{(page.seo_description)?page.seo_description:''}}</p>
                                    </v-card-text>
                                </v-card>
                                <div class="headline" v-if="page.title">
                                    Social Share
                                    <strong>Image</strong>
                                </div>
                                <v-card class="search-preview" v-if="page.title">
                                    <v-img
                                            height="250"
                                            width="100%"
                                            v-bind:src="page.social_share_img ? page.social_share_img : '/assets/media/logos/icon-no-image.svg'"
                                    >
                                        <v-overlay :absolute="absolute">
                                            <v-btn text @click="showDialog">Change Image</v-btn>
                                        </v-overlay>
                                    </v-img>

                                    <p>{{siteUrl}}/{{page.custom_slug}}</p>
                                    <h3>{{(page.seo_title)?page.seo_title:""}}</h3>
                                    <p>{{(page.seo_description)?page.seo_description:''}}</p>
                                </v-card>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-dialog>
            <v-dialog scrollable v-model="socialShareDiaolog" persistent max-width="700px">
                <v-card>
                    <v-card-title>
                        <span class="headline">Manage Social Share Image</span>
                    </v-card-title>
                    <v-card-text>
                        <v-container>
                            <div class="form-block">
                                <v-col cols="12" md="12">
                                    <v-file-input
                                            :rules="rules"
                                            v-model="page.social_share_image"
                                            accept="image/png, image/jpeg, image/bmp"
                                            placeholder="Pick an Featured Image"
                                            prepend-inner-icon="mdi-camera"
                                            persistent-hint
                                            @change="imageChange"
                                            hint="Also used for social Share"
                                            label="Featured Image"
                                            full-width
                                            width="100%"
                                    ></v-file-input>
                                    <span class="text-danger">{{errorMsg}}</span>
                                </v-col>
                                <v-col cols="12">
                                    <div id="preview">
                                        <img v-if="url" :src="url"/>
                                    </div>
                                </v-col>
                            </div>
                        </v-container>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="blue darken-1" text @click="closeDialog">Close</v-btn>
                        <v-btn
                                color="blue darken-1"
                                text
                                :loading="isBusy"
                                @click.prevent="uploadSocialImage"
                        >Save
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-app>
    </div>
</template>

<script>
    import {SET_BREADCRUMB} from "@/store/breadcrumbs.module";
    import KTPortlet from "@/views/partials/content/Portlet.vue";
    import {required} from "vuelidate/lib/validators";
    import Website from "@/services/Websites/WebsiteService";
    import WebsitePage from "@/services/Websites/WebsitePageService";

    const WebsiteService = new Website();
    const WebsitePageService = new WebsitePage();
    export default {
        name: "website-seo",
        components: {
            KTPortlet
        },
        validations: {
            page: {
                title: {required},
                slug: {required}
            }
        },
        watch: {
            pageIndex(newValue, oldValue) {
                this.page = this.pages[this.pageIndex];
            }
        },
        data() {
            return {
                errorMsg: null,
                isBusy: false,
                pageIndex: 0,
                absolute: true,
                overlay: false,
                socialShareDiaolog: false,
                sortBy: "title",
                search: "",
                model: "",
                sortDesc: false,
                loading: true,
                rules: [
                    value =>
                        !value ||
                        value.size < 2000000 ||
                        "Avatar size should be less than 2 MB!"
                ],
                url: null,
                // date: new Date().toISOString().substr(0, 7),
                fields: [
                    {key: "title", label: "Title", sortable: true},
                    {key: "seo_title", label: "SEO Title", sortable: true},
                    {key: "description", label: "Description", sortable: true},
                    {key: "action", label: "Action", sortable: false}
                ],
                // totalRows: 1,
                // currentPage: 1,
                // perPage: 10,
                // pageOptions: [5, 10, 15],
                page: {
                    id: 1,
                    title: "",
                    slug: "",
                    custom_slug: "",
                    content: null,
                    main_css: "",
                    section_css: "",
                    seo_title: "Website Title",
                    seo_description: "Some description on your website page",
                    social_share_img: "",
                    social_share_description: "",
                    is_deleted: 0,
                    is_active: 1,
                    deleted_at: null,
                    updated_at: ""
                },
                pages: []
            };
        },
        methods: {
            changePage(value) {
                this.edit_seo = true;
                this.page = value
            },
            cancel(){
                this.edit_seo = false;
                this.page = {};
            },
            showDialog() {
                this.socialShareDiaolog = true;
            },
            closeDialog() {
                this.socialShareDiaolog = false;
            },
            delele(id) {

            },
            savePage() {
                this.$v.$touch();
                if (!this.$v.page.$error) {
                    //call api
                    //close Dialog
                    //refesh Data
                    // success Notification
                }
            },
            saveSeoSetting() {
                this.isBusy = true;
                delete this.page.social_share_image;
                WebsitePageService.update(this.siteUrl, this.page.id, this.page).then(
                    res => {
                        this.isBusy = false;
                        this.edit_seo = false;
                        this.$snotify.success("Page Seo Updated");
                        this.getPages();
                    }
                ).catch(err => {
                }).finally(() => (this.isBusy = false));
            },
            uploadSocialImage() {
                this.errorMsg = null;
                let fd = new FormData();
                let file = "";
                for (let key in this.page) {
                    if (key == "social_share_image") {
                        file =
                            this.page["social_share_image"] != null &&
                            this.page["social_share_image"] != undefined
                                ? this.page["social_share_image"]
                                : "";
                        fd.append(key, file);
                    }
                }
                if (!file) {
                    this.errorMsg = "Image Required";
                } else {
                    this.url = null
                    this.isBusy = true;
                    WebsitePageService.update(this.siteUrl, this.page.id, fd).then(res => {
                        this.page.social_share_img = res.data.page.social_share_img;
                        this.closeDialog();
                        this.$snotify.success("Page Seo Updated");
                        this.isBusy = false;
                        this.page.social_share_image = null;
                        this.getPages();
                    }).catch(err => {
                    }).finally(() => (this.isBusy = false));
                }
            },
            toggleBusy() {
                this.isBusy = !this.isBusy;
            },
            imageChange(e) {
                this.url = URL.createObjectURL(e);
            },
            getPages(type = "default", flag = null) {
                WebsitePageService.paginate(this.siteUrl, type, flag).then(res => {
                    this.pages = res.data.pages;
                    this.pages.forEach(page => {
                        // console.log(page.slug)
                        if (page.slug == this.pageSlug) {
                            this.page = page;

                        }
                    });
                    this.loading=false;
                });
            }
        },
        computed: {
            rows() {
                return this.items.length;
            },
            siteUrl() {
                return this.$route.params.domainname;
            },
            pageSlug() {
                return this.$route.params.page;
            }
        },
        mounted() {
            this.getPages();

            this.$store.dispatch(SET_BREADCRUMB, [
                {title: "Manage Pages", route: "/websites"}
            ]);
        }
    };
</script>

<style>
    .seo-title {
        color: darkblue !important;
    }

    #preview {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    #preview img {
        max-width: 100%;
        max-height: 250px;
    }
</style>
